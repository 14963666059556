
let soundLock = null;
let soundUnlock = null;

export function playLockSound() {

	if(soundLock) {
		soundLock.play().catch(() => {
			console.warn("Audio play not allowed");
		});
	} else {
		soundLock = (new Audio('https://img.qromo.io/audio/qromo-lock.mp3'));
		soundLock.play().catch(() => {
			console.warn("Audio play not allowed");
		});
	}

}

export function playUnlockSound() {
	if (soundUnlock) {
		soundUnlock.play().catch(() => {
			console.warn("Audio play not allowed");
		});
	} else {
		soundUnlock = (new Audio('https://img.qromo.io/audio/qromo-unlock.mp3'));
		soundUnlock.play().catch(() => {
			console.warn("Audio play not allowed");
		});
	}
}
