
export function isAndroid() {
	return typeof Android !== "undefined";
}

export function isIOS() {
	return typeof iOSNativeV2 !== 'undefined' && typeof window.webkit !== "undefined" && typeof window.webkit.messageHandlers !== "undefined";
}

export function isNative() {
	return isAndroid() || isIOS();
}

export function getNative() {
	if (isAndroid()) {
		return new Proxy(Android, {
			get: (target, prop) => {
				return function(...args) {
					return new Promise((resolve, reject) => {
						if (typeof target[prop] === "function") {
							try {
								let response = target[prop](...args);
								response = typeof response === "undefined" ? null : response;
								resolve(response);
							} catch (error) {
								reject(error);
							}
						} else {
							resolve(null);
						}
					});
				};
			}
		});
	}
	if (isIOS()) {
		return new Proxy({}, {
			get: function(target, prop) {
				if (window.webkit && window.webkit.messageHandlers) {
					return function(...args) {
						return new Promise((resolve, reject) => {
							const random = Math.random().toString(36).substring(7);
							const callbackId = `cb_${prop}_${Date.now()}_${random}`;
							window[callbackId] = function(response) {
								resolve(response);
								delete window[callbackId];
							};
							try {
								if(typeof window.webkit.messageHandlers[prop] !== 'undefined') {
									window.webkit.messageHandlers[prop].postMessage({ data: args, callback: callbackId });
								}
							} catch (error) {
								reject(error);
							}
						});
					};
				} else {
					console.warn(`No message handler found for ${prop}`);
					return function() {
						return Promise.resolve(null);
					};
				}
			}
		});
	}
	return null;
}