import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useEpsonStore, useUserStore, useRoomsOrdersStore} from "@/console-new/stores";


export const usePluginsStore = defineStore('plugins', {
	state: () => ({
		externalPlugins: [],
	}),
	getters: {
		userStore: () => useUserStore(),
		pluginsMap: state => {
			let returningMap = {};
			if(state.externalPlugins && state.externalPlugins.length) {
				for(let i in state.externalPlugins) {
					let plugin = state.externalPlugins[i];
					returningMap[plugin.plugin_id] = plugin;
				}
			}
			return returningMap;
		}
	},
	actions: {
		resetData() {
			this.externalPlugins = [];
		},
		refreshExternalPlugins(callback = null) {
			if(!this.userStore.currBusinessId) { return; }
			API.init().getPlugins(this.userStore.currBusinessId, (r) => {
				let success = r && r.result && r.result === "OK";
				if(success) {
					this.normalizeExternalPlugins(r.plugins, r.plugins_active);
				}
				callback && callback(success);
			});
		},
		normalizeExternalPlugins(plugins, pluginsActive) {
			if(plugins) {
				for(let i in plugins) {
					let plugin = plugins[i];
					plugin.plugin_id = Number(plugin.plugin_id);
					plugin.business_id = plugin.business_id ? Number(plugin.business_id) : null;
					plugin.price_month_amount = plugin.price_month_amount ? Number(plugin.price_month_amount) : null;
					plugin.price_year_amount = plugin.price_year_amount ? Number(plugin.price_year_amount) : null;
					plugin.order_fixed_fee = plugin.order_fixed_fee ? Number(plugin.order_fixed_fee) : null;
					plugin.selected = false;
					plugin.externalLocationId = null;
					plugin.require_secret = plugin.require_secret === "1";
				}
				this.externalPlugins = Object.values(plugins).sort((a, b) => !!a.business_id ? (Number(a.plugin_id) > Number(b.plugin_id) ? 1 : -1) : -1);
			}
			if(pluginsActive) {
				for(let i in pluginsActive) {
					let currPlugin = pluginsActive[i];
					let activePlugin = this.pluginsMap[currPlugin.plugin_id];
					if(activePlugin) {
						activePlugin.selected = currPlugin.active === "1";
						activePlugin.externalLocationId = currPlugin.external_location_id;
						activePlugin.paid = currPlugin.paid ? currPlugin.paid === "1" : null;
					}
				}
			}
		},
		disablePlugin(pluginId, callback = null) {
			API.init().disablePlugin(this.userStore.currBusinessId, pluginId, (r) => {
				let success = r && r.result && r.result === "OK";
				if(success) {
					this.refreshExternalPlugins(callback);
				} else {
					callback && callback(false);
				}
			});
		},
		enablePlugin(pluginId, externalLocationId, secret, callback = null) {
			API.init().activatePlugin(this.userStore.currBusinessId, pluginId, externalLocationId, secret, (r) => {
				if(r && r.result && r.result === "OK") {
					this.refreshExternalPlugins(callback);
				} else if(r && r.result && r.result === "KO" && r.details && ["not_paid", "invalid_response", "no_response"].includes(r.details)) {
					callback && callback(r.details);
				} else {
					callback && callback(false);
				}
			});
		},
		toggleExternalPlugin(pluginId, enable, callback = null) {
			API.init().stripeToggleExternalPlugins(this.userStore.currBusinessId, pluginId, !!enable, (r) => {
				if(r && r.result && r.result === "OK") {
					setTimeout(() => {
						this.refreshExternalPlugins(callback);
					}, 2000);
				} else {
					callback && callback(false);
				}
			});
		},
		deletePlugin(pluginId, callback = null) {
			API.init().deletePlugin(this.userStore.currBusinessId, pluginId, (r) => {
				if(r && r.result && r.result === "OK") {
					this.refreshExternalPlugins(callback);
				} else {
					callback && callback(false);
				}
			});
		}
	},
});
