import { defineStore } from 'pinia';
import {useOrdersStore} from "@/console-new/stores/orders.store";

export const usePollingStore = defineStore('polling', {
	state: () => ({
		pollInterval: null,
		isPolling: false,
		pollCounter: 0,
	}),
	getters: {
	},
	actions: {
		startPolling() {
			if(this.pollInterval === null) {
				this.poll();
			} else {
				this.stopPolling();
				setTimeout(() => {
					this.startPolling();
				}, 1000);
			}
		},
		poll() {
			this.pollInterval = setTimeout(() => {
				this.isPolling = true;
				let ordersStore = useOrdersStore();
				ordersStore.getOrdersUpdates(() => {
					this.isPolling = false;
					this.poll();

					if(this.pollCounter % 5 === 0) {
						ordersStore.tablesToCheck = true;
						if(TEST) {
							if(this.pollCounter > 0 && this.pollCounter % 1000 === 0) {
								console.debug("Polling counter: ", this.pollCounter);
							}
						}
					}
					this.pollCounter += 1;
				});
			}, 5000);
		},
		stopPolling() {
			clearTimeout(this.pollInterval);
			this.pollInterval = null;
		},
		forcePoll(callback = null) {
			if(!this.isPolling) {
				this.stopPolling();

				this.isPolling = true;
				let ordersStore = useOrdersStore();
				ordersStore.getOrdersUpdates(() => {
					this.pollCounter += 1;
					this.isPolling = false;
					this.poll();
					callback && callback();
				});

			} else {
				callback && callback();
			}
		}
	}
});







