import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useLanguageStore} from "@/shared/stores";

let languages = false;
let allergens = false;
let themes = false;

export const useUnchangingStore = defineStore('unchanging', {
	state: () => ({
		ready: false,
		languages: [],
		allergens: {},
		themes: [],
		offline: false,
	}),
	getters: {
		orderedAllergens: state => {
			let getString = useLanguageStore().getString;
			return Object.values(state.allergens).sort((a, b) => {
				return getString(a.name_key).localeCompare(getString(b.name_key));
			});
		}
	},
	actions: {
		init() {
			this.downloadLanguages();
			this.downloadAllergens();
			this.downloadThemes();
		},
		downloadLanguages() {

			if(typeof languagesData !== "undefined" && languagesData) {
				this.languages = languagesData;
				languages = true;
				if(allergens && themes) {
					this.ready = true;
				}
				return;
			}

			API.init().getLanguages((response) => {
				if(response && response.result === "OK") {
					languages = true;
					this.languages = response.languages;
					if(allergens && themes) {
						this.ready = true;
					}
				}
			});

		},
		downloadThemes() {

			if(typeof themesData !== "undefined" && themesData && themesData.length) {
				this.themes = themesData.sort((a, b) => {
					return Number(a.sort_number) - Number(b.sort_number);
				});
				themes = true;
				if(allergens && languages) {
					this.ready = true;
				}
				return;
			}

			API.init().getThemes((response) => {
				if(response && response.result === "OK") {
					themes = true;
					this.themes = response.menu_themes.sort((a, b) => {
						return Number(a.sort_number) - Number(b.sort_number);
					});
					if(allergens && languages) {
						this.ready = true;
					}
				}
			});

		},
		downloadAllergens() {

			if(typeof allergensData !== "undefined" && allergensData) {
				this.allergens = allergensData;
				allergens = true;
				if(languages && themes) {
					this.ready = true;
				}
				return;
			}

			API.init().getAllergens((response) => {
				if(response && response.result === "OK") {
					allergens = true;
					this.allergens = {};
					response.allergens.forEach(allergen => {
						this.allergens[allergen.allergen_id] = allergen;
					});
					if(languages && themes) {
						this.ready = true;
					}
				}
			});

		},
		isReady(callback) {
			if (this.ready) {
				callback();
			} else {
				setTimeout(() => {
					this.isReady(callback);
				}, 50);
			}
		},
	}
});
