import { defineStore } from 'pinia';
import API from "@/shared/helpers/API";
import {useUserStore, useRoomsStore} from "@/console-new/stores";
import {isAndroid, isNative} from "@/shared/helpers/webapp";

export const useRoomsOrdersStore = defineStore('roomsOrders', {
	state: () => ({
		shouldOpenFirstRoomWithNotification: false,
		loadingViewedCall: false,
		askedAssistanceFilter: false,
		lastPlayedCallSoundTime: null,
		minSecondsBetweenSounds: 30,
		roomsTablesCalls: [],
	}),
	getters: {
		roomsStore: state => useRoomsStore(),
		rtcIds: state => {
			let finalIds = {};
			if(!state.roomsTablesCalls) return finalIds;
			for(let i = 0; i < state.roomsTablesCalls.length; i++) {
				finalIds[state.roomsTablesCalls[i].room_table_call_id] = state.roomsTablesCalls[i];
			}
			return finalIds;
		},
		rtcTablesIds: state => {
			let finalIds = {};
			if(!state.roomsTablesCalls) return finalIds;
			for(let i = 0; i < state.roomsTablesCalls.length; i++) {
				let currentItem = state.roomsTablesCalls[i];
				if(!finalIds[currentItem.room_table_id]) {
					finalIds[currentItem.room_table_id] = {};
				}
				finalIds[currentItem.room_table_id][currentItem.room_table_call_id] = currentItem;
			}
			return finalIds;
		},
		rtcRoomsIds: state => {
			let finalIds = {};
			if(!state.roomsTablesCalls) return finalIds;
			for(let i = 0; i < state.roomsTablesCalls.length; i++) {
				let currentItem = state.roomsTablesCalls[i];
				if(!finalIds[currentItem.room_id]) {
					finalIds[currentItem.room_id] = {};
				}
				finalIds[currentItem.room_id][currentItem.room_table_call_id] = currentItem;
			}
			return finalIds;
		}
	},
	actions: {
		playNewRoomTableCallSound(roomId) {
			if(this.lastPlayedCallSoundTime) {
				if(new Date().getTime() - this.lastPlayedCallSoundTime < this.minSecondsBetweenSounds * 1000) {
					return;
				}
			}
			if(!isAndroid()) {
				const foundRoom = this.roomsStore.roomsIds[roomId];
				if(!foundRoom || !foundRoom.notification_sound) {
					return;
				}
				this.lastPlayedCallSoundTime = new Date().getTime();
				if(foundRoom.audioNewOrder) {
					foundRoom.audioNewOrder.play().catch(() => {
						console.warn("Audio play not allowed");
					});
				} else {
					foundRoom.audioNewOrder = (new Audio('https://img.qromo.io/audio/'+foundRoom.notification_sound+'.mp3'));
					foundRoom.audioNewOrder.play().catch(() => {
						console.warn("Audio play not allowed");
					});
				}
			}
		},
		checkNewCallsToPlaySound(receivedCalls) {
			if(this.lastPlayedCallSoundTime) {
				if(new Date().getTime() - this.lastPlayedCallSoundTime < this.minSecondsBetweenSounds * 1000) {
					return;
				}
			}
			let newCalls = receivedCalls || [];
			if(newCalls && newCalls.length) {
				for(let i = 0; i < newCalls.length; i++) {
					let roomTableCall = newCalls[i];
					if(roomTableCall.room_table_call_id && !this.rtcIds[roomTableCall.room_table_call_id]) {
						this.playNewRoomTableCallSound(roomTableCall.room_id);
						return;
					}

				}
			}
		},
		getFirstRoomIdWithNotification() {
			if(!this.roomsStore.rooms?.length || !this.roomsTablesCalls?.length) { return null; }
			return this.roomsTablesCalls.find(call => call.room_id && this.roomsStore.roomsIds[call.room_id])?.room_id;
		},
		viewedRoomTableCall(roomTableId, callback = null) {
			if(this.loadingViewedCall) { return; }
			this.loadingViewedCall = true;
			API.init().viewedRoomTableCall(useUserStore().currBusinessId, roomTableId, (r) => {
				let successUpdate = r && r.result && r.result === "OK";
				if(successUpdate) {
					this.roomsTablesCalls = this.roomsTablesCalls.filter(call => call.room_table_id !== roomTableId);
				}
				this.loadingViewedCall = false;
				callback && callback(successUpdate);
			});
		},
		enabledTableCalls() {
			return this.roomsStore.rooms?.length && this.roomsStore.rooms?.find && !!this.roomsStore.rooms.find(room => room.enabledCalls && room.acceptOrders);
		},
		resetRoomsTablesCalls() {
			this.roomsTablesCalls = [];
			this.askedAssistanceFilter = false;
		},
		normalizeRoomsTablesCalls(calls) {
			return calls.map(call => {
				call.room_table_call_id = Number(call.room_table_call_id);
				call.room_table_id = Number(call.room_table_id);
				call.room_id = Number(call.room_id);
				call.business_id = Number(useUserStore().currBusinessId);
				call.viewed = false;
				return call;
			})
		},
		initRoomsTablesCalls(calls) {
			let newCalls = this.normalizeRoomsTablesCalls(calls || []);
			this.checkNewCallsToPlaySound(newCalls);
			this.roomsTablesCalls = newCalls;
		},
	}
});