import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores/user.store";

export const useSocialsStore = defineStore('socials', {
	state: () => ({
		availableSocials: [],
		socialCollection: [],
	}),
	getters: {
		businessSocials(state) {
			return state.availableSocials.map(social => {
				let item = state.socialCollection.find(item => item.type === social.type);
				return {
					social_id: social.social_id,
					type: social.type,
					url: social.url,
					value: item ? item.value : null,
				}
			});
		}
	},
	actions: {
		refreshSocials(callback = null) {
			let self = this;
			let userStore = useUserStore();
			API.init().getBusinessSocials(userStore.currBusinessId, response=>{
				if (response && response["result"] === "OK") {
					self.availableSocials = response["available_socials"];
					self.socialCollection = response["social_collection"];
					callback && callback();
				}
			});
		},
		saveSocials(socials, callback = null) {
			let userStore = useUserStore();
			API.init().saveSocialUrl(userStore.currBusinessId, JSON.stringify(socials), response=>{
				if (response && response["result"] === "OK") {
					callback && callback(response.result, response.details);
				}
			});
		}
	}
});

function getMaxLanguagesFromLevel(websiteLevel) {
	if(websiteLevel === "custom") {
		return 4;
	} else if(websiteLevel === "pro") {
		return 6;
	} else {
		return 0;
	}
}