import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores/user.store";
import {getRandomAlphanumericString} from "@/shared/helpers/strings";
import {useUnchangingStore, useFunnelStore} from "@/console-new/stores";

export const useMenuStore = defineStore('menu', {
	state: () => ({
		loadedBusinessMenu: false,
		loadedAndNormalizedMenu: false,
		items: {},
		categories: {},
		menus: {},
		modMenu: {
			categories: {},
			orphanItems: {},
		},
		aiToolsActive: false,
		shouldCreateCategory: false,
	}),
	getters: {
		categoryFromItemId() {
			return (itemId) => {
				if (itemId && this.items[itemId] && this.items[itemId].menu_category_id) {
					return this.categories[this.items[itemId].menu_category_id];
				}
				return null;
			}
		},
		modCategoryFromItemId() {
			return (itemId) => {
				let category = this.categoryFromItemId(itemId);
				return category ? this.modMenu.categories[category.tempid] : null;
			}
		},
		modItemFromId() {
			return (itemId) => {
				let itemTempId = this.items?.[itemId]?.tempid;
				if(!itemTempId) { return {} }
				let modCategory = this.modCategoryFromItemId(itemId);
				return modCategory ? modCategory.items[itemTempId] : this.modMenu.orphanItems[itemTempId];
			}
		},
		modItemFromTempIds() {
			return (categoryTempId, itemTempId) => {
				if(categoryTempId) {
					let category = this.modMenu.categories[categoryTempId];
					return category.items[itemTempId];
				} else {
					return this.modMenu.orphanItems[itemTempId];
				}
			}
		},
		itemsWithSlug() {
			return (slug) => {
				let totalItems = 0;
				for(let catTempId in this.modMenu.categories) {
					let currCheckingCategory = this.modMenu.categories[catTempId];
					for(let itemTempId in currCheckingCategory.items) {
						if(currCheckingCategory.items[itemTempId].slug === slug) {
							totalItems++;
						}
					}
				}
				for(let itemTempId in this.modMenu.orphanItems) {
					if(this.modMenu.orphanItems[itemTempId].slug === slug) {
						totalItems++;
					}
				}
				return totalItems;
			}
		},
		selectedItems() {
			return () => {

				let selectedItems = [];
				Object.values(this.modMenu.categories).forEach(cat => {
					Object.values(cat.items || {}).forEach(item => {
						if(item.selected) { selectedItems.push(item); }
					});
				});
				Object.values(this.modMenu.orphanItems).forEach(item => {
					if(item.selected) { selectedItems.push(item); }
				});
				return selectedItems;

			}
		},
		itemsNum: state => {

			let totalItems = 0;
			Object.values(state.modMenu.categories).forEach(cat => {
				totalItems += Object.values(cat.items || {}).length;
			});
			totalItems += Object.values(state.modMenu.orphanItems).length;
			return totalItems;
		}
	},
	actions: {
		resetData() {
			this.loadedBusinessMenu = false;
			this.loadedAndNormalizedMenu = false;
			this.items = {};
			this.categories = {};
			this.menus = {};
			this.modMenu = {
				categories: {},
				orphanItems: {},
			};
		},
		getUniqueItemSlug(slug, randomize = false) {
			let result = slug;
			while(Object.values(this.items).find(m => m.slug && m.slug === result)) {
				result += "-1";
			}
			if(randomize) {
				result += "-" + getRandomAlphanumericString(6);
			}
			return result;
		},
		getUniqueMenuSlug(slug) {
			let result = slug;
			while(Object.values(this.menus).find(m => m.slug === result)) {
				result += "-1";
			}
			return result;
		},
		refreshMenusOnly(callback = null) {

			let userStore = useUserStore();
			if (!userStore.currBusinessId) {
				return;
			}

			let self = this;
			API.init().getMenus(userStore.currBusinessId, function (response) {
				if (response && response.result === "OK" && response.menus) {
					self.menus = {};
					response.menus.forEach(menu => {
						self.menus[menu.menu_id] = menu;
					});

					if (callback) {
						callback();
					}
				}
			});
		},
		refreshMenusData(callback = null) {

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { return; }

			let self = this;
			self.loadedBusinessMenu = true;
			API.init().getMenus(userStore.currBusinessId, function (response) {
				if (response && response.result === "OK" && response.menus) {
					let newMenus = {};
					response.menus.forEach(menu => {
						newMenus[menu.menu_id] = menu;
					});
					self.menus = newMenus;

					API.init().getMenuCategories(userStore.currBusinessId, function (response) {
						if (response && response.result === "OK") {
							let shouldSendProductsEvent = false;
							if(useFunnelStore()?.currStep?.trackEventId === 'step1_allinone' && self.items && Object.keys(self.items).length < 3) {
								shouldSendProductsEvent = true;
							}
							self.categories = {};
							self.items = {};
							response.categories.forEach(category => {
								self.categories[category.menu_category_id] = category;
							});

							API.init().getMenuItems(userStore.currBusinessId, function (response) {
								if (response && response.result === "OK") {
									response.items.forEach(item => {
										self.items[item.menu_item_id] = item;
									});
									if(shouldSendProductsEvent && Object.keys(self.items).length >= 3) {
										useFunnelStore().sendZarazStepEvent('step1_allinone');
									}
									self.normalizeAllMenus(response.items_allergens, response.images_items, () => {
										self.loadedAndNormalizedMenu = true;
									});

									callback && callback(true);
								} else {
									callback && callback(false);
								}
							});

						} else {
							callback && callback(false);
						}
					});
				}
			});

		},
		getMenuIndex(menuId) {

			let found = null;
			if (menuId) {
				Object.values(this.menus).sort((a, b) => {
					return a.order_number - b.order_number;
				}).forEach((m, i) => {
					if (parseInt(m.menu_id) === parseInt(menuId)) {
						found = i;
					}
				});
			}
			return found;

		},
		loadMenu(callback = null) {
			if(this.loadedBusinessMenu) {
				if(callback) { callback(); }
			} else {
				this.refreshMenusData(()=>{
					if(callback) { callback(); }
				});
			}
		},
		normalizeAllMenus(itemsAllergens, itemsImages, callback) {

			let self = this;
			let unchangingStore = useUnchangingStore();

			unchangingStore.isReady(function () {

				Object.entries(self.menus).forEach(([menuId, menu]) => {
					self.normalizeMenu(menu);
				});

				Object.entries(self.categories).forEach(([key, category]) => {
					self.normalizeCategory(category);

					let menu = self.menus[category.menu_id];
					if(menu) {
						menu.categories[category.menu_category_id] = category;
					}
				});

				Object.entries(self.items).forEach(([key, item]) => {
					self.normalizeItem(item);

					let category = item.menu_category_id ? self.categories[item.menu_category_id] : null;
					if(category) {
						category.items[item.menu_item_id] = item;
					}
				});

				//Associate images to items
				itemsImages.forEach(menuItemImage => {
					if(self.items.hasOwnProperty(menuItemImage.menu_item_id)) {
						self.items[menuItemImage.menu_item_id].pictures.push({
							"menu_item_image_id": menuItemImage.menu_item_image_id,
							"url": menuItemImage.url,
							"sort_number": menuItemImage.sort_number
						});
					}
				});

				//Associate allergens to items
				itemsAllergens.forEach(itemAllergen => {
					itemAllergen.allergen = unchangingStore.allergens[itemAllergen.allergen_id];
					if(self.items.hasOwnProperty(itemAllergen.menu_item_id)) {
						self.items[itemAllergen.menu_item_id].allergens_link.push(itemAllergen);
					}
				});

				let lastMenuId = Number(localStorage.getItem("last_menu_id"));

				if(lastMenuId && self.menus[lastMenuId]) {
					self.changeModMenu(lastMenuId);
				} else {
					let menus = Object.values(self.menus);
					if(menus.length > 0) {
						self.changeModMenu(Object.values(self.menus)[0].menu_id);
					}
				}

				callback();
			});

		},
		changeModMenu(menuId) {
			let self = this;
			let modMenuCategories = {};
			let modMenuOrphanItems = {};

			Object.entries(self.categories).forEach(([key, category]) => {
				if(category.menu_id === menuId) {
					modMenuCategories[category.tempid] = JSON.parse(JSON.stringify(category));
					modMenuCategories[category.tempid].collapsed = true;
					modMenuCategories[category.tempid].collapsedDelayed = true;
					modMenuCategories[category.tempid].items = {};
				}
			});

			Object.entries(self.items).forEach(([key, item]) => {

				if(item.deleted_item) {
					return;
				}

				let itemCategory = item.menu_category_id ? self.categories[item.menu_category_id] : null;
				if(itemCategory === null || typeof itemCategory === "undefined") {
					modMenuOrphanItems[item.tempid] = JSON.parse(JSON.stringify(item));
					modMenuOrphanItems[item.tempid].active = false;
					modMenuOrphanItems[item.tempid].collapsedDelayed = true;
					modMenuOrphanItems[item.tempid].customSlot = "item-header";
					modMenuOrphanItems[item.tempid].dropdownSlot = "item-dropdown";
					modMenuOrphanItems[item.tempid].menu_category_id = null;

				} else if (itemCategory.menu_id === menuId) {
					itemCategory.items[item.menu_item_id] = item;

					modMenuCategories[itemCategory.tempid].items[item.tempid] = JSON.parse(JSON.stringify(item));
					modMenuCategories[itemCategory.tempid].items[item.tempid].active = false;
					modMenuCategories[itemCategory.tempid].items[item.tempid].collapsedDelayed = true;
					modMenuCategories[itemCategory.tempid].items[item.tempid].customSlot = "item-header";
					modMenuCategories[itemCategory.tempid].items[item.tempid].dropdownSlot = "item-dropdown";
				}

			});

			self.modMenu = {
				menu_id: menuId,
				categories: modMenuCategories,
				orphanItems: modMenuOrphanItems,
				name: self.menus[menuId].name,
				color: self.menus[menuId].color,
				picture: self.menus[menuId].picture,
				slug: self.menus[menuId].slug,
				available: self.menus[menuId].available,
				counter_available: self.menus[menuId].counter_available,
				order_number: self.menus[menuId].order_number,
			}
			this.normalizeMenu(self.modMenu, true);
			localStorage.setItem("last_menu_id", menuId);
		},
		addNewMenu(name, callback = null) {

			let userStore = useUserStore();
			API.init().newMenu(userStore.currBusinessId, name, (response) => {
				if(response.result === "OK" && response.new_menu_id) {
					let newMenu = {
						name: response.new_menu_name,
						menu_id: response.new_menu_id,
						order_number: response.new_menu_order_number || 0,
					}
					this.normalizeMenu(newMenu);
					this.menus[newMenu.menu_id] = newMenu;
					this.changeModMenu(newMenu.menu_id);
					callback && callback();
				}
			});

		},
		deleteMenu(menuId, callback = null) {

			let userStore = useUserStore();

			API.init().deleteMenu(userStore.currBusinessId, menuId, (response) => {
				if(response.result === "OK") {
					this.refreshMenusData(() => {
						callback && callback(true, null);
					});
				} else {
					if(response && response.details) {
						callback && callback(false, response.details);
					} else {
						callback && callback(false, null);
					}
				}
			});

		},
		updateMenu(menu, roomsExclude, callback = null) {

			let userStore = useUserStore();
			API.init().updateMenu(userStore.currBusinessId, menu.menu_id, menu.name === "" ? null : menu.name, menu.color, menu.picture, menu.slug, menu.available, menu.counter_available, JSON.stringify(roomsExclude), (response) => {
				if(response.result === "OK") {
					this.menus[menu.menu_id].name = menu.name;
					callback && callback();
				}
			});

		},
		normalizeMenu(menu, isUpdate = false) {

			menu.menu_id = Number(menu.menu_id||0);
			menu.categories = menu.hasOwnProperty("categories") ? menu.categories : {};
			menu.orphanItems = menu.hasOwnProperty("orphanItems") ? menu.orphanItems : {};
			menu.name = menu.hasOwnProperty("name") ? menu.name : null;
			menu.color = menu.hasOwnProperty("color") && menu.color !== "null" ? menu.color : null;
			menu.picture = menu.hasOwnProperty("picture") && menu.picture !== "null" ? menu.picture : null;
			menu.slug = menu.hasOwnProperty("slug") && menu.slug !== "null" ? menu.slug : null;
			if(isUpdate) {
				menu.available = menu.hasOwnProperty("available") ? !!menu.available : false;
				menu.counter_available = menu.hasOwnProperty("counter_available") ? !!menu.counter_available : false;
			} else {
				menu.available = menu.hasOwnProperty("available") ? menu.available === "1" : true;
				menu.counter_available = menu.hasOwnProperty("counter_available") ? menu.counter_available === "1" : true;
			}
			menu.order_number = Number(menu.order_number||0);

		},
		normalizeCategory(category, isUpdate = false) {
			let userStore = useUserStore();

			if(!isUpdate) {
				category.tempid = getRandomAlphanumericString(10);
			}
			category.order_number = Number(category.order_number||0);
			category.menu_id = Number(category.menu_id||0);
			category.dropdown = category.dropdown === "1";
			category.grid_mode = category.grid_mode === "1";
			category.carousel = category.carousel === "1";
			category.featured = category.featured === "1";
			category.variations_only = category.variations_only === "1";
			category.active_delivery = category.active_delivery === "1";
			category.active_takeaway = category.active_takeaway === "1";
			category.active = category.active === null ? null : !!Number(category.active);
			category.description = category.description ? category.description : null;
			category.taxonomy = category.taxonomy ? category.taxonomy : null;
			category.editActive = false;
			category.editActiveDelayed = false;
			category.droppable = false;
			category.droppableHover = false;
			category.color = category.color ? "#" + category.color.replace(/#/g, "") : (userStore.currBusiness.color ? "#" + userStore.currBusiness.color.replace(/#/g, "") : "#0752A3");
			category.items = {};
		},
		addNewCategory(prefillFields = null) {

			let userStore = useUserStore();

			let newOrder = Object.values(this.modMenu.categories).length ? Math.max(...Object.values(this.modMenu.categories).map(category => category.order_number)) + 1 : 0;

			let defaultCategory = {
				"tempid": getRandomAlphanumericString(10),
				"business_id": userStore.currBusinessId,
				"name": prefillFields && prefillFields.hasOwnProperty("name") ? prefillFields.name : "",
				"description": prefillFields && prefillFields.hasOwnProperty("description") ? prefillFields.description : null,
				"picture": null,
				"color": userStore.currBusinessColor,
				"active": true,
				"active_delivery": true,
				"active_takeaway": true,
				"variations_only": false,
				"dropdown": true,
				"vat": null,
				"featured": false,
				"import_id": null,
				"order": newOrder,
				"order_number": newOrder,
				"editActive": true,
				"editActiveDelayed": true,
				"collapsed": true,
				"collapsedDelayed": true,
				"grid_mode": false,
				"carousel": false,
				"taxonomy": null,
				"items": {}
			};

			this.modMenu.categories[defaultCategory.tempid] = defaultCategory;

			return defaultCategory;
		},
		normalizeItem(item, isUpdate = false) {
			if(!isUpdate) {
				item.tempid = getRandomAlphanumericString(10);
				item.active = false;
				item.collapsedDelayed = true;
			}
			item.menu_item_id = Number(item.menu_item_id);
			item.menu_category_id = item.category_id;
			item.available = item.available === null ? null : !!Number(item.available);
			item.vat = item.vat === null ? null : Number(item.vat);
			item.redeemable_variations = item.redeemable_variations === "1";
			item.cross_selling_priority = item.cross_selling_priority === null ? null : Number(item.cross_selling_priority);
			item.price = Number(item.price);
			item.full_price = item.full_price === null ? null : Number(item.full_price);
			delete item.category_id;
			item.order_number = Number(item.order);
			item.deepen = item.deepen === "1";
			item.composite = item.composite === "1";
			item.compositeLoaded = false;
			item.note = item.note === "1";
			item.deleted_item = item.deleted_item === "1";
			item.warehouse = item.warehouse === "1";
			item.pictures = [];
			item.allergens_link = [];
			item.video_link = item.video_link ? item.video_link : null;
			item.sku = item.sku ? item.sku : null;
		},
		addNewItem(categoryTempId, prefillFields = null) {

			let menuCategoryId = categoryTempId ? this.modMenu.categories[categoryTempId].menu_category_id : null;

			let newOrder = 0;

			if(menuCategoryId && Object.values(this.modMenu.categories[categoryTempId].items).length) {
				newOrder = Math.max(...Object.values(this.modMenu.categories[categoryTempId].items).map(i => i.order_number)) + 1;
			} else if(!menuCategoryId && Object.values(this.modMenu.orphanItems).length) {
				newOrder = Math.max(...Object.values(this.modMenu.orphanItems).map(i => i.order_number)) + 1;
			}

			let defaultItem = {
				"tempid": getRandomAlphanumericString(10),
				"name": prefillFields && prefillFields.hasOwnProperty("name") ? prefillFields.name : "",
				"price": prefillFields && prefillFields.hasOwnProperty("price") ? prefillFields.price : 0,
				"full_price": 0,
				"picture": null,
				"ingredients": prefillFields && prefillFields.hasOwnProperty("ingredients") ? prefillFields.ingredients : null,
				"available": true,
				"deepen": false,
				"composite": false,
				"compositeLoaded": false,
				"slug": null,
				"long_description": null,
				"video_link": null,
				"materials": null,
				"color": null,
				"note": false,
				"vat": null,
				"cross_selling_priority": null,
				"stock": null,
				"order": newOrder,
				"order_number": newOrder,
				"deleted_item": 0,
				"active": true,
				"collapsedDelayed": false,
				"menu_category_id": menuCategoryId,
				"pictures": [],
				"allergens_link": [],
				"orderability": "standard",
				"customSlot": "item-header",
				"dropdownSlot": "item-dropdown",
				"sku": null,
				"warehouse": false
			}

			if(menuCategoryId) {
				this.modMenu.categories[categoryTempId].items[defaultItem.tempid] = defaultItem;
			}

			return defaultItem;
		},
		duplicateItem(originalModItem, targetCategoryTempId) {

			let originalItemCategory = originalModItem.menu_category_id ? this.categories[originalModItem.menu_category_id] : null;

			let menuCategoryId = originalItemCategory ? originalItemCategory.menu_category_id : null;
			let categoryTempId = originalItemCategory ? originalItemCategory.tempid : null;

			let newOrder = 0;

			if(targetCategoryTempId && Object.values(this.modMenu.categories[targetCategoryTempId].items).length) {
				newOrder = Math.max(...Object.values(this.modMenu.categories[targetCategoryTempId].items).map(i => i.order_number)) + 1;
			} else if(menuCategoryId && Object.values(this.modMenu.categories[categoryTempId].items).length) {
				newOrder = Math.max(...Object.values(this.modMenu.categories[categoryTempId].items).map(i => i.order_number)) + 1;
			} else if(!menuCategoryId && Object.values(this.modMenu.orphanItems).length) {
				newOrder = Math.max(...Object.values(this.modMenu.orphanItems).map(i => i.order_number)) + 1;
			}

			let newItemValues = {
				"tempid": getRandomAlphanumericString(10),
				"order": newOrder,
				"order_number": newOrder,
				"menu_item_id": null,
				"lists": null,
				"selected": false,
				"sku": null,
				"warehouse": false
			};
			let newItem = Object.assign({}, originalModItem, newItemValues);
			newItem.slug = newItem.slug ? this.getUniqueItemSlug(newItem.slug, true) : newItem.slug;

			if(targetCategoryTempId) {
				newItem.menu_category_id = this.modMenu.categories[targetCategoryTempId].menu_category_id;
				this.modMenu.categories[targetCategoryTempId].items[newItem.tempid] = newItem;
			} else if(menuCategoryId) {
				this.modMenu.categories[categoryTempId].items[newItem.tempid] = newItem;
			} else {
				this.modMenu.orphanItems[newItem.tempid] = newItem;
			}

			return newItem;
		},
		duplicateSelectedItems(targetCategoryTempId, callback) {
			let itemsIds = this.selectedItems().map(i => i.menu_item_id).filter(i => i !== null);
			this.getMenuItemsListsInfo(itemsIds,(fetchedItemsListsInfo, errorDetails) => {
				if(fetchedItemsListsInfo) {

					let newItemsLinks = {};
					this.selectedItems().forEach(item => {
						let duplicateItem = Object.assign({}, this.duplicateItem(item, targetCategoryTempId));
						duplicateItem.old_menu_item_id = item.menu_item_id;
						newItemsLinks[duplicateItem.tempid] = duplicateItem;
					});

					this.saveMenu((newItems, errorDetails) => {
						if(newItems) {
							let pushingLists = [];
							let itemsIdsObj = {};
							for(let tempId in newItems) {
								let newItemLinked = newItemsLinks[tempId];
								if(newItemLinked) {
									let oldItemId = newItemLinked.old_menu_item_id;

									let categoryId = newItemLinked.menu_category_id;
									let categoryTempId = categoryId ? this.categories[categoryId].tempid : null;
									let currItem = categoryTempId ? this.modMenu.categories[categoryTempId].items[tempId] : this.modMenu.orphanItems[tempId];
									currItem.menu_item_id = Number(newItems[tempId]);

									if(oldItemId) {
										itemsIdsObj[oldItemId] = newItems[tempId];
										let currItemLists = fetchedItemsListsInfo.lists.filter(i => Number(i.menu_item_id) === Number(oldItemId));
										if(currItemLists.length) {
											currItem.lists = {};
											currItemLists.forEach(list => {
												list.menu_item_id = currItem.menu_item_id;
												this.normalizeList(list);
												currItem.lists[list.tempid] = list;
												let currItemListItems = fetchedItemsListsInfo.lists_products.filter(i => Number(i.menu_item_list_id) === Number(list.menu_item_list_id));
												currItemListItems.forEach(listItem => {
													this.normalizeListProduct(listItem);
													listItem.menu_item_list_id = null;
													listItem.menu_item_list_product_id = null;
													listItem.menu_item_id = currItem.menu_item_id;
													currItem.lists[list.tempid].list_products = currItem.lists[list.tempid].list_products || {};
													currItem.lists[list.tempid].list_products[listItem.tempid] = listItem;
												})
											});
											pushingLists.push({'menu_item_id': currItem.menu_item_id, 'lists': currItem.lists});
										}
									}

									this.items[currItem.menu_item_id] = Object.assign({}, newItemLinked, currItem);
									if(categoryId && this.categories[categoryId]) {
										this.categories[categoryId].items[currItem.menu_item_id] = Object.assign({}, this.items[currItem.menu_item_id], currItem);
									}
								}
							}

							let shouldCopyTimes = fetchedItemsListsInfo.items_times && fetchedItemsListsInfo.items_times.length;
							let copyTimesFunction = (itemsObj, cb) => {
								if(shouldCopyTimes) {
									API.init().copyItemsTimes(useUserStore().currBusinessId, itemsObj, resp => {
										if(resp && resp.result === "OK") {
											cb(true);
										} else {
											cb(false, resp ? resp.details : null);
										}
									});
								} else {
									cb(true);
								}
							}

							if(pushingLists.length) {
								let userStore = useUserStore();
								API.init().saveListsInformation(userStore.currBusinessId, pushingLists, resp => {
									if(resp && resp.result === "OK") {
										copyTimesFunction(itemsIdsObj, callback);
									} else {
										callback(false, resp ? resp.details : null);
									}
								});
							} else {
								copyTimesFunction(itemsIdsObj, callback);
							}
						} else {
							callback(false, errorDetails);
						}
					}, true);

				} else {
					callback(false, errorDetails);
				}
			});
		},
		normalizeList(list, isUpdate = false) {
			if(!isUpdate) {
				list.tempid = getRandomAlphanumericString(10);
				list.active = false;
				list.collapsedDelayed = true;
				list.customSlot = 'title-controls';
				list.dropdownSlot = 'dropdown-content';
				list.headerSlot = 'controls';
			}
			list.menu_item_list_id = Number(list.menu_item_list_id);
			list.title = list.name;
			list.select_min = Number(list.select_min);
			list.select_max = Number(list.select_max);
			list.select_multi = !!Number(list.select_multi);
			list.order_number = Number(list.order_number);
			list.deleted_list = !!Number(list.deleted_list);
		},
		normalizeListProduct(prod, isUpdate = false) {
			if(!isUpdate) {
				prod.tempid = getRandomAlphanumericString(10);
				prod.customSlot = 'summary-prod';
			}
			prod.menu_item_list_product_id = Number(prod.menu_item_list_product_id);
			prod.menu_item_id = Number(prod.menu_item_id);
			prod.meta_product = !!Number(prod.meta_product);
			prod.nested = !!Number(prod.nested);
			prod.available = !!Number(prod.available);
			prod.menu_item_linked_id = prod.menu_item_linked_id ? Number(prod.menu_item_linked_id) : null;
			prod.linked_price = prod.linked_price === '1';
			prod.linked_name = prod.linked_name === '1';
			prod.order_number = Number(prod.order_number || 0);
			prod.available = prod.available === null ? null : !!Number(prod.available);
			prod.deleted_product = !!Number(prod.deleted_product);
			prod.list_order_number = null;

			if(!prod.meta_product && prod.menu_item_linked_id) {
				let linkedProduct = this.items[prod.menu_item_linked_id];
				if(linkedProduct) {
					prod.available = linkedProduct.available;
					if(prod.linked_price || prod.linked_name) {
						prod.product_price = prod.linked_price ? linkedProduct.price : prod.product_price;
						prod.product_name = prod.linked_name ? linkedProduct.name : prod.product_name;
					}
				}
			}

			prod.title = prod.product_name;
			prod.product_price = Number(prod.product_price);

		},
		getItemTimetables(menuItemId, callback) {

			let userStore = useUserStore();

			API.init().getItemTimes(userStore.currBusinessId, menuItemId, function (response) {
				if (response && response.result === "OK" && response.item_times) {
					callback(response.item_times.map(t => {
						return {
							hour_start: Number(t.hour_start),
							hour_end: Number(t.hour_end),
							minute_start: Number(t.minute_start),
							minute_end: Number(t.minute_end),
							week_day: Number(t.date_week),
							menu_item_id: Number(t.item_id),
							item_time_id: Number(t.item_time_id),
							quantity: t.num_items ? Number(t.num_items) : null,
						};
					}));
				}
			});

		},
		getCategoryTimetables(menuCategoryId, callback) {

			let userStore = useUserStore();

			API.init().getCategoryTimes(userStore.currBusinessId, menuCategoryId, function (response) {
				if (response && response.result === "OK" && response.category_times) {
					callback(response.category_times.map(t => {
						return {
							hour_start: Number(t.hour_start),
							hour_end: Number(t.hour_end),
							minute_start: Number(t.minute_start),
							minute_end: Number(t.minute_end),
							week_day: Number(t.date_week),
							category_id: Number(t.category_id),
							category_time_id: Number(t.category_time_id),
							quantity: t.num_items ? Number(t.num_items) : null,
						};
					}));
				}
			});

		},
		updateItemTimetables(menuItemId, timetables, callback) {

			let userStore = useUserStore();

			let times = timetables.map(t => {
				return {
					hour_start: t.hour_start,
					hour_end: t.hour_end,
					minute_start: t.minute_start,
					minute_end: t.minute_end,
					date_week: t.week_day,
					item_id: t.menu_item_id,
					item_time_id: t.item_time_id ? t.item_time_id : null,
					num_items: t.quantity,
				};
			});

			API.init().updateItemTimes(userStore.currBusinessId, menuItemId, JSON.stringify(times), function (result) {
				if(result && result.result === "OK") {
					callback();
				}
			});

		},
		updateCategoryTimetables(menuCategoryId, timetables, callback) {

			let userStore = useUserStore();

			let times = timetables.map(t => {
				return {
					hour_start: t.hour_start,
					hour_end: t.hour_end,
					minute_start: t.minute_start,
					minute_end: t.minute_end,
					date_week: t.week_day,
					category_id: t.category_id,
					category_time_id: t.category_time_id ? t.category_time_id : null,
					num_items: t.quantity,
				};
			});

			API.init().updateCategoryTimes(userStore.currBusinessId, menuCategoryId, JSON.stringify(times), function (result) {
				if(result && result.result === "OK") {
					callback();
				}
			});

		},
		saveMenu(callback, returnNewItems = false) {

			let self = this;
			this.saveCategories(function(success, errorDetails) {
				if(success) {
					self.saveItems(callback, returnNewItems);
				} else {
					callback(false, errorDetails);
				}
			});

		},
		saveCategories(callback) {

			let userStore = useUserStore();

			let self = this;
			API.init().updateMenuCategories(userStore.currBusinessId, JSON.stringify(this.modMenu.categories), this.modMenu.menu_id, function (response) {
				if(response && response.result === 'OK') {
					if(response.new_categories) {
						response.new_categories.forEach((newCat) => {
							self.modMenu.categories[newCat.tempid].menu_category_id = Number(newCat.menu_category_id);
							self.categories[newCat.menu_category_id] = JSON.parse(JSON.stringify(self.modMenu.categories[newCat.tempid]));
							let currentCat = self.modMenu.categories[newCat.tempid];
							if(currentCat.items && Object.keys(currentCat.items).length > 0) {
								for(let itemId in currentCat.items) {
									currentCat.items[itemId].menu_category_id = Number(newCat.menu_category_id);
								}
							}
						});
					}
					callback(true);
				} else {
					let details = response && response.details ? response.details : null;
					callback(false, details);
				}
			});

		},
		getMenuItemsListsInfo(menuItemsIds, callback) {

			let userStore = useUserStore();
			API.init().getMenuItemsListsInfo(userStore.currBusinessId, menuItemsIds, function (response) {
				if (response && response.result === "OK") {
					callback(response);
				} else {
					callback(false, response.details);
				}
			})

		},
		getCompositeProduct(menuItemId, callback) {

			let userStore = useUserStore();

			let self = this;
			API.init().getCompositeProduct(userStore.currBusinessId, menuItemId, function (response) {

				let modItem = self.modItemFromId(menuItemId);
				modItem.lists = {};
				if (response && response.result === "OK" && response.menu_item_lists && response.menu_item_lists[menuItemId]) {
					for(let i = 0; i<response.menu_item_lists[menuItemId].length; i++) {
						let list = response.menu_item_lists[menuItemId][i];
						if(!list.deleted_list || list.deleted_list === "0") {
							self.normalizeList(list);
							modItem.lists[list.tempid] = list;
						}
					}
				}
				callback();
			})

		},
		getListInformation(menuItemId, modListId, callback) {

			let userStore = useUserStore();
			let modItem = this.modItemFromId(menuItemId);
			let currentList = modItem.lists[modListId];
			let listId = currentList.menu_item_list_id;
			currentList.list_products = {};
			let self = this;

			API.init().getListInformation(userStore.currBusinessId, menuItemId, listId, function (response) {
				if (response && response.result === "OK" && response.menu_item_list_products && response.menu_item_list_products && response.menu_item_list_products[listId]) {
					for(let i = 0; i<response.menu_item_list_products[listId].length; i++) {
						let prod = response.menu_item_list_products[listId][i];
						self.normalizeListProduct(prod);
						prod.list_order_number = currentList.order_number;
						currentList.list_products[prod.tempid] = prod;
					}
				}
				callback();
			});

		},
		getAllCompositeInfo(itemId, callback) {

			let userStore = useUserStore();
			let self = this;
			API.init().getMenuItemListsInfo(userStore.currBusinessId, itemId, true, false, (response) => {
				if (response && response.result === "OK" && response.lists && response.lists.length) {
					self.items[itemId].lists = {};
					response.lists.forEach(list => {
						if(list) {
							self.normalizeList(list);
							self.items[itemId].lists[list.menu_item_list_id] = list;
							self.items[itemId].lists[list.menu_item_list_id].products = {};
						}
					});
					if(response.lists_products && response.lists_products.length > 0) {
						response.lists_products.forEach(listProd => {
							self.normalizeListProduct(listProd);
							if(self.items[itemId].lists.hasOwnProperty(listProd.menu_item_list_id)) {
								listProd.list_order_number = self.items[itemId].lists[listProd.menu_item_list_id].order_number;
								self.items[itemId].lists[listProd.menu_item_list_id].products[listProd.menu_item_list_product_id] = listProd;
							}
						});
					}
					self.items[itemId].compositeLoaded = true;
					callback(true);
				} else {
					callback(false);
				}
			});

		},
		saveSingleItem(itemToSend, callback) {

			let self = this;
			let userStore = useUserStore();
			let unchangingStore = useUnchangingStore();

			let sendingItem = Object.assign({}, itemToSend);
			sendingItem.category_id = sendingItem.menu_category_id;
			sendingItem.price = sendingItem.price / 100;
			sendingItem.full_price = sendingItem.full_price ? sendingItem.full_price / 100 : null;

			sendingItem.listToUpdate = sendingItem.lists ? JSON.stringify(sendingItem.lists) : [];
			sendingItem.video_link = sendingItem.video_link ? sendingItem.video_link : null;

			API.init().menuItem(userStore.currBusinessId, JSON.stringify(sendingItem), function (response) {

				if (response && response.result === "OK") {

					if (response.item_insert && response.item_insert.items && response.item_insert.items.length > 0) {

						let newItem = response.item_insert.items[0];
						self.normalizeItem(newItem, true);

						if (response.item_insert.images_items) {
							response.item_insert.images_items.forEach(image => {
								newItem.pictures.push({
									"menu_item_image_id": image.image_id,
									"url": image.url,
									"sort_number": image.sort_number
								});
							});
						}
						if(response.item_insert.items_allergens) {
							response.item_insert.items_allergens.forEach(itemAllergen => {
								itemAllergen.allergen = unchangingStore.allergens[itemAllergen.allergen_id];
								newItem.allergens_link.push(itemAllergen);
							});
						}

						let oldModItem = self.modItemFromTempIds(itemToSend.menu_category_id ? self.categories[itemToSend.menu_category_id].tempid : null, itemToSend.tempid);
						if(self.items.hasOwnProperty(newItem.menu_item_id)) {
							updateObject(newItem, self.items[newItem.menu_item_id]);
						} else {
							if(useFunnelStore()?.currStep?.trackEventId === 'step1_allinone' && self.items && Object.keys(self.items).length === 2) {
								useFunnelStore().sendZarazStepEvent('step1_allinone');
							}
							self.items[newItem.menu_item_id] = newItem;
							self.items[newItem.menu_item_id].tempid = oldModItem.tempid;
							if(newItem.menu_category_id && self.categories.hasOwnProperty(newItem.menu_category_id)) {
								self.categories[newItem.menu_category_id].items[newItem.menu_item_id] = newItem;
							}
						}
						updateObject(newItem, oldModItem);
					}

					callback(true, response.details);
				} else {
					callback(false, response.details);
				}
			});

		},
		saveItems(callback, returnNewItems = false) {

			let self = this;
			let userStore = useUserStore();

			let itemsToSend = [];
			for(let cId in self.modMenu.categories) {
				let category = self.modMenu.categories[cId];
				for(let iId in category.items) {
					let item = category.items[iId];

					let itemToSend = JSON.parse(JSON.stringify(item));
					itemToSend.category_id = itemToSend.menu_category_id;
					itemToSend.price = itemToSend.price / 100;
					itemToSend.full_price = itemToSend.full_price ? itemToSend.full_price / 100 : null;

					itemToSend.listToUpdate = itemToSend.lists ? JSON.stringify(itemToSend.lists) : [];

					itemsToSend.push(itemToSend);
				}
			}
			for(let iOI in self.modMenu.orphanItems) {
				let item = self.modMenu.orphanItems[iOI];
				let itemToSend = JSON.parse(JSON.stringify(item));
				itemToSend.category_id = null;
				itemToSend.price = itemToSend.price / 100;
				itemToSend.full_price = itemToSend.full_price ? itemToSend.full_price / 100 : null;

				itemToSend.listToUpdate = itemToSend.lists ? JSON.stringify(itemToSend.lists) : [];

				itemsToSend.push(itemToSend);
			}

			API.init().updateMenuItems(userStore.currBusinessId, JSON.stringify(itemsToSend), self.modMenu.menu_id, function (response) {

				if (response && response.result === "OK") {
					callback(returnNewItems && response['new_items'] ? response['new_items'] : true);
				} else {
					let details = response && response.details ? response.details : null;
					callback(false, details);
				}
			}, returnNewItems);

		},
		saveSingleList(item, listTempId, callback) {

			let userStore = useUserStore();

			let list = item.lists[listTempId];
			let listItems = list.list_products ? Object.values(list.list_products) : [];
			let listId = list.menu_item_list_id || 'new';

			API.init().saveListInformation(userStore.currBusinessId, item.menu_item_id, listId, list.name, list.select_min, list.select_max, list.select_multi, list.order_number, list.deleted_list, JSON.stringify(listItems), (response) => {

				let returning = false;
				let details = false;
				let updatedListProducts = false;
				if(response && response.result === "OK" && response.menu_item_lists && response.menu_item_lists[item.menu_item_id]) {
					returning = response.menu_item_lists[item.menu_item_id];
					updatedListProducts = response.menu_item_list_products ? response.menu_item_list_products : [];
					for(let prod in updatedListProducts) {
						this.normalizeListProduct(updatedListProducts[prod]);
					}
				} else {
					details = response && response.details ? response.details : false;
				}
				callback(returning, updatedListProducts, details);

			});

		},
		updateProductLists(itemId, lists, callback) {

			let userStore = useUserStore();

			if(!lists || !lists.length) {
				callback(true);
				return;
			} else {

				API.init().updateMenuItemLists(userStore.currBusinessId, itemId, lists, response => {

					let returning = false;
					let details = false;
					if(response && response.result === "OK" && response.menu_item_lists && response.menu_item_lists[itemId]) {
						returning = response.menu_item_lists[itemId];
					} else {
						details = response && response.details ? response.details : false;
					}
					callback(returning, details);

				});

			}

		},
		hasPricedItem() {
			return Object.values(this.items || {}).some(item => item.price > 0);
		}
	}
});

function updateObject(objFrom, objTo) {
	for (let key in objFrom) {
		if (objFrom.hasOwnProperty(key)) {
			objTo[key] = objFrom[key];
		}
	}
}
