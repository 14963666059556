import { defineStore } from 'pinia';
import {getNative, isIOS, isNative} from "@/shared/helpers/webapp";

export const useNativeStore = defineStore('native', {
	state: () => ({
		ereceiptMode: 0,
		fiscalMode: 0,
		fiscalIp: "",
		soundIndex: 0,
		isFontDoubleSize: false,
		isFixedSizeRight: false,
		isPrintTotal: false,
		isAllowActions: true,
		isPrintBig: false,
		isQrFiscalReceipt: false,
		rtNotNative: false,
		excludedCategories: [],
		excludedStations: []
	}),
	getters: {

	},
	actions: {
		setEreceiptMode() {
			if(isNative()) {
				getNative().getIntPreference("ereceiptMode").then((ereceiptMode) => {
					this.ereceiptMode = ereceiptMode > 0 ? ereceiptMode : 0;
				});
			}
			this.ereceiptMode = 0;
		},
		setFiscalMode() {
			if(isNative()) {
				getNative().getIntPreference("fiscalMode").then((fiscalMode) => {
					if(fiscalMode === 3) {
						this.fiscalMode = 0;
					} else {
						this.fiscalMode = 1;
					}
				});
			}
			this.fiscalMode = 0;
		},
		setFiscalIp() {
			if (isNative()) {
				getNative().getStringPreference("fiscal_ip").then((fiscalIp) => {
					this.fiscalIp = fiscalIp;
				});
			}
			this.fiscalIp = "";
		},
		setSoundIndex() {
			if(isNative()) {
				getNative().getIntPreference("sound").then((soundIndex) => {
					this.soundIndex = soundIndex > 0 ? soundIndex : 0;
				});
			}
			this.soundIndex = 0;
		},
		setFontDoubleSize() {
			if(isNative()) {
				getNative().getBooleanPreference("fontDoubleSize", false).then((isFontDoubleSize) => {
					this.isFontDoubleSize = !!isFontDoubleSize;
				});
			}
			this.isFontDoubleSize = false;
		},
		setFixedSizeRight() {
			if(isNative()) {
				getNative().getBooleanPreference("fixedSizeRight", false).then((isFixedSizeRight) => {
					this.isFixedSizeRight = !!isFixedSizeRight;
				});
			}
			this.isFixedSizeRight = false;
		},
		setPrintTotal() {
			if(isNative()) {
				getNative().getBooleanPreference("printTotal", false).then((isPrintTotal) => {
					this.isPrintTotal = !!isPrintTotal;
				});
			}
			this.isPrintTotal = false;
		},
		setAllowActions() {
			if(isNative()) {
				getNative().getBooleanPreference("denyActions", false).then((isDenyActions) => {
					this.isAllowActions = !isDenyActions;
				});
			}
			this.isAllowActions = true;
		},
		setPrintBig() {
			if(isNative()) {
				getNative().getBooleanPreference("printBig", false).then((isPrintBig) => {
					this.isPrintBig = !!isPrintBig;
				});
			}
			this.isPrintBig = false;
		},
		setQrFiscalReceipt() {
			if(isNative()) {
				getNative().getBooleanPreference("qrFiscalReceipt", false).then((isQrFiscalReceipt) => {
					this.isQrFiscalReceipt = !!isQrFiscalReceipt;
				});
			}
			this.isQrFiscalReceipt = false;
		},
		setRtNotNative() {
			if(isNative()) {
				getNative().getBooleanPreference("rtNotNative", false).then((rtNotNative) => {
					this.rtNotNative = !!rtNotNative;
				});
			}
			this.rtNotNative = false;
		},
		setExcludedCategories() {
			if(isNative()) {
				getNative().getStringPreference("excludedCategories").then((excludedCategories) => {
					this.excludedCategories = excludedCategories ? JSON.parse(excludedCategories) : [];
				});
			}
			this.excludedCategories = [];
		},
		setExcludedStations() {
			if(isNative()) {
				getNative().getStringPreference("excludedStations").then((excludedStations) => {
					this.excludedStations = excludedStations ? JSON.parse(excludedStations) : [];
				});
			}
			this.excludedStations = [];
		},
		refreshPreferences() {
			this.setEreceiptMode();
			this.setFiscalMode();
			this.setFiscalIp();
			this.setSoundIndex();
			this.setFontDoubleSize();
			this.setFixedSizeRight();
			this.setPrintTotal();
			this.setAllowActions();
			this.setPrintBig();
			this.setQrFiscalReceipt();
			this.setExcludedCategories();
			this.setExcludedStations();
			this.setRtNotNative();
		}
	}
});







