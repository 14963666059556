import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores";
import {useLanguageStore} from "@/shared/stores";
import {getLocalDateFromServer} from "@/shared/helpers/dates";

export const useWarehouseStore = defineStore('warehouse', {
	state: () => ({
		warehouseProducts: [],
		savedEdits: {},
	}),
	getters: {
		userStore: () => { return useUserStore() },
		warehouseItemsSKUs: state => {
			let finalIds = {};
			if(state.warehouseProducts?.length) {
				state.warehouseProducts.forEach((item) => {
					if(item.sku) {
						finalIds[item.sku] = item;
					}
				});
			}
			return finalIds;
		},
	},
	actions: {
		resetData() {
			this.warehouseProducts = [];
		},
		getWarehouse(callback = null) {
			if(!this.userStore?.currBusinessId) {
				if(callback) { callback(false); }
				return;
			}
			if(!this.userStore?.currBusiness?.warehouse) {
				if(callback) { callback(true); }
				return;
			}
			API.init().getWarehouseItems(this.userStore.currBusinessId, r=>{

				if(r && r.result === "OK") {
					if(r.menu_items_warehouse && r.menu_items) {
						this.warehouseProducts = [];
						r.menu_items_warehouse.forEach(t => {
							this.warehouseProducts.push(this.normalizeWarehouseItem(t, r.menu_items));
						});
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}

			});
		},
		normalizeWarehouseItem(item, skuItems) {
			item.menu_item_warehouse_id = Number(item.menu_item_warehouse_id || 0);
			item.stock = Number(item.stock || 0);
			item.buy_price = Number(item.buy_price || 0);
			if(this.savedEdits[item.menu_item_warehouse_id]) {
				item.stock = this.savedEdits[item.menu_item_warehouse_id].stock;
				item.buy_price = this.savedEdits[item.menu_item_warehouse_id].buy_price;
				item.unit = this.savedEdits[item.menu_item_warehouse_id].unit;
			}
			item.last_update = getLocalDateFromServer(item.last_update);
			item.bom = item.bom === "1";
			item.affects_availability = item.affects_availability !== null ? Number(item.affects_availability) : null;
			if(skuItems.hasOwnProperty(item.sku)) {
				item.name = skuItems[item.sku].name;
				item.price = Number(skuItems[item.sku].price || 0);
				item.category_id = skuItems[item.sku].category_id ? Number(skuItems[item.sku].category_id) : null;
			} else {
				item.name = item.sku+" ("+useLanguageStore()?.getString('not_found')+")";
				item.price = 0;
				item.category_id = null;
			}
			item.profit = item.price - item.buy_price;
			item.unit_of_measure = item.unit;
			if(item.unit === "pz") {
				item.unit_of_measure = useLanguageStore()?.getString('pieces');
			}
			item.stockEdit = false;
			item.buyEdit = false;
			return item;
		},
		updateWarehouseItems(items, callback = null) {
			if(!this.userStore?.currBusinessId) {
				if(callback) { callback(false); }
				return;
			}
			if(items?.length) {
				this.savedEdits = {};
				API.init().updateWarehouseItems(this.userStore.currBusinessId, items, r => {
					if(r && r.result === "OK") {
						if(callback) { callback(true); }
					}
				});
			} else {
				if(callback) { callback(null); }
			}
		}
	}
});
