import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores";
export const useMarketplaceStore = defineStore('marketplace', {
	state: () => ({
		shouldOpen: null,
		showPluginNotification: false,
	}),
	getters: {},
	actions: {
		updateBusinessPlugins(plan, callback = null) {

			let userStore = useUserStore();
			if(!userStore || !userStore.currPlans || !userStore.currBusinessId) {
				if(callback) { callback(false); }
				return;
			}
			let plans = JSON.parse(JSON.stringify(userStore.currPlans));
			let orderPay = plans.order_pay;
			let delivery = plans.delivery;
			let takeaway = plans.takeaway;
			let pos = plans.pos;
			let counter = plans.counter;
			let payBill = plans.pay_bill;
			if(plan === 'pos') {
				pos = !pos;
			}
			if(plan === 'counter') {
				counter = !counter;
			}
			if(plan === 'order_pay') {
				orderPay = !orderPay;
				if(!orderPay) {
					delivery = false;
					takeaway = false;
				}
			} else if(plan === 'delivery') {
				delivery = !delivery;
				if(delivery) { orderPay = true;	}
			} else if(plan === 'takeaway') {
				takeaway = !takeaway;
				if(takeaway) { orderPay = true;	}
			} else if(plan === 'pay_bill') {
				payBill = !payBill;
			}
			if(!orderPay) {
				delivery = false;
				takeaway = false;
			}
			if(takeaway || delivery) { orderPay = true; }
			API.init().stripeBuyPlugins(userStore.currBusinessId, orderPay, delivery, takeaway, pos, counter, payBill, (response) => {
				userStore.refreshBusiness(userStore.currBusinessId,function () {
					if ((plan === 'pos' || plan === 'counter') || (response && response.result === "OK")) {
						if(callback) { callback(true); }
					} else {
						if(callback) { callback(false, response.details); }
					}
				});
			});

		},
	},
});
