import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores/user.store";

export const useCouponsStore = defineStore('coupons', {
	state: () => ({
		businessCoupons: null,
	}),
	getters: {
	},
	actions: {
		getBusinessCoupons(callback = null, force = false) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) { return; }

			if(!force) {
				if(this.businessCoupons != null) { callback && callback(this.businessCoupons); return; }
			}

			API.init().getCoupons(userStore.currBusinessId, response => {
				if (response && response.result === "OK") {
					if(response.coupons && response.coupons.length > 0) {
						this.normalizeCoupons(response.coupons, response.coupons_products, response.coupons_rooms, response.orders);
						this.businessCoupons = response.coupons;
					} else {
						this.businessCoupons = [];
					}
					callback && callback(this.businessCoupons);
				}
			});
		},
		normalizeCoupons(coupons, couponsProducts, couponsRooms, orders) {

			let couponsProductsObj = {};
			let couponsCategoriesObj = {};
			for(let i = 0; i < couponsProducts.length; i++) {
				if(couponsProducts[i].item_id) {
					if(!couponsProductsObj[couponsProducts[i].business_coupon_id]) {
						couponsProductsObj[couponsProducts[i].business_coupon_id] = [];
					}
					couponsProductsObj[couponsProducts[i].business_coupon_id].push(couponsProducts[i]);
				} else if(couponsProducts[i].category_id) {
					if(!couponsCategoriesObj[couponsProducts[i].business_coupon_id]) {
						couponsCategoriesObj[couponsProducts[i].business_coupon_id] = [];
					}
					couponsCategoriesObj[couponsProducts[i].business_coupon_id].push(couponsProducts[i]);
				}
			}

			let couponsRoomsObj = {};
			for(let i = 0; i < couponsRooms.length; i++) {
				if(!couponsRoomsObj[couponsRooms[i].business_coupon_id]) {
					couponsRoomsObj[couponsRooms[i].business_coupon_id] = [];
				}
				couponsRoomsObj[couponsRooms[i].business_coupon_id].push(couponsRooms[i]);
			}

			let ordersObj = {};
			for(let i = 0; i < orders.length; i++) {
				if(!ordersObj[orders[i].business_coupon_id]) {
					ordersObj[orders[i].business_coupon_id] = [];
				}
				ordersObj[orders[i].business_coupon_id].push(orders[i]);
			}

			for(let i = 0; i < coupons.length; i++) {
				coupons[i].business_coupon_id = Number(coupons[i].business_coupon_id);
				coupons[i].can_stack = coupons[i].can_stack === "1";
				coupons[i].once_per_user = coupons[i].once_per_user === "1";
				coupons[i].first_time_only = coupons[i].first_time_only === "1";
				coupons[i].delivery = coupons[i].delivery === "1";
				coupons[i].takeaway = coupons[i].takeaway === "1";
				coupons[i].qr_mode = coupons[i].qr_mode === "1";
				coupons[i].linked_products = coupons[i].linked_products === "1";
				coupons[i].linked_rooms = coupons[i].linked_rooms === "1";
				coupons[i].minimum_order_value = coupons[i].minimum_order_value ? Number(coupons[i].minimum_order_value) : null;
				coupons[i].products = couponsProductsObj[coupons[i].business_coupon_id] || [];
				coupons[i].categories = couponsCategoriesObj[coupons[i].business_coupon_id] || [];
				coupons[i].rooms = couponsRoomsObj[coupons[i].business_coupon_id] || [];
				coupons[i].orders = ordersObj[coupons[i].business_coupon_id] || [];

				coupons[i].active = false;
				coupons[i].productsDropdownActive = false;
				coupons[i].categoriesDropdownActive = false;
				coupons[i].roomsDropdownActive = false;
				coupons[i].customSlot = 'coupon-slot';
				coupons[i].dropdownSlot = 'coupon-dropdown';
				coupons[i].title = coupons[i].name;
			}

		},
	}
});







