import { defineStore } from 'pinia';
import API from "@/shared/helpers/API";
import {useUserStore, useRoomsStore, useMenuStore} from "@/console-new/stores";
import {useLanguageStore} from "@/shared/stores";

export const useFunnelStore = defineStore('funnel', {
	state: () => ({
		loadingFunnelData: false,
		wentToShop: false,
		businessActions: null,
		loadingActions: false,
		pollingTimeout: null,
		pollingIterations: 0,
		fetchingBusiness: false,
	}),
	getters: {
		userStore: state => useUserStore(),
		menuStore: () => useMenuStore(),
		roomsStore: () => useRoomsStore(),
		currBusiness: () => useUserStore()?.currBusiness,
		getString: () => useLanguageStore()?.getString,
		currentOrderAction: state => {
			if(!state?.currStep || !state.currStep?.listItems) {
				return null
			}
			for(let i in state.currStep.listItems) {
				let currSubStep = state.currStep.listItems[i];
				if(!currSubStep.completed && currSubStep.businessActionId) {
					return currSubStep.businessActionId
				}
			}
			return null;
		},
		counterFunnelSteps: state => {
			return [
				{
					title: state.getString('products_registry'),
					trackEventId: 'step1_allinone',
					slotName: 'products-registry',
					completed: state.completedProductsRegistry,
					video: {
						id: 'NWXP7zFyPf4',
						title: state.getString('funnel_video_registry_title'),
						description: state.getString('funnel_video_registry_description'),
					}
				},
				{
					title: state.getString('rooms_creation'),
					trackEventId: 'step2_allinone',
					completed: state.completedRoomCreation,
					slotName: 'room-creation',
					video: {
						id: 'fhMkgiU1kJY',
						title: state.getString('funnel_video_rooms_title'),
						description: state.getString('funnel_video_rooms_description'),
					}
				},
				{
					title: state.getString('go_live_free_trial'),
					completed: state.completedGoLiveCounter,
					slotName: 'go-live',
				},
				{
					title: state.getString('funnel_stripe_step_title'),
					completed: state.completedStripeLink,
					slotName: 'receive-payments',
					video: {
						id: 'N3JRhr6nAx4',
						title: state.getString('funnel_video_stripe_title'),
						description: state.getString('funnel_video_stripe_description'),
					}
				},
				{
					title: state.getString('funnel_user_manual'),
					trackEventId: 'step5_allinone',
					shouldLoadActions: true,
					completed: !!(state.businessActions?.includes('order_prepared') && state.businessActions?.includes('order_directly_paid') && state.businessActions?.includes('order_edited') && state.businessActions?.includes('order_paid_prepared') && state.businessActions?.includes('order_canceled')),
					slotName: 'list-content-guide',
					video: {
						id: 'Ry16kySbssc',
						title: state.getString('funnel_video_manual_title'),
						description: state.getString('funnel_video_manual_description'),
					},
					listItems: [
						{
							title: state.getString('funnel_counter_user_manual_title_1'),
							completed: state.businessActions?.includes('order_prepared'),
							slotName: 'cta-step',
							businessActionId: 'order_prepared',
							//ctaIcon: 'icon-direct-sale-primary',
							text: state.getString('funnel_counter_user_manual_text_1'),
							routerTo: "/ops/counter",
							ctaText: state.getString('go_to_counter_sale'),
							videoStart: 20,
							videoEnd: 75,
						},
						{
							title: state.getString('funnel_counter_user_manual_title_2'),
							completed: state.businessActions?.includes('order_directly_paid'),
							slotName: 'cta-step',
							showProductsPricesWarning: true,
							businessActionId: 'order_directly_paid',
							text: state.getString('funnel_counter_user_manual_text_2'),
							routerTo: "/ops/counter",
							ctaText: state.getString('go_to_counter_sale'),
							videoStart: 76,
							videoEnd: 164,
						},
						{
							title: state.getString('funnel_counter_user_manual_title_3'),
							completed: state.businessActions?.includes('order_edited'),
							slotName: 'cta-step',
							businessActionId: 'order_edited',
							//ctaIcon: 'icon-orders-primary',
							text: state.getString('funnel_counter_user_manual_text_3'),
							routerTo: "/ops/orders",
							ctaText: state.getString('go_to_orders'),
							videoStart: 154,
							videoEnd: 231,
						},
						{
							title: state.getString('funnel_counter_user_manual_title_4'),
							completed: state.businessActions?.includes('order_paid_prepared'),
							slotName: 'cta-step',
							businessActionId: 'order_paid_prepared',
							//ctaIcon: 'icon-orders-primary',
							text: state.getString('funnel_counter_user_manual_text_4'),
							routerTo: "/ops/orders",
							ctaText: state.getString('go_to_orders'),
							videoStart: 232,
							videoEnd: 266,
						},
						{
							title: state.getString('funnel_counter_user_manual_title_5'),
							completed: state.businessActions?.includes('order_canceled'),
							slotName: 'cta-step',
							businessActionId: 'order_canceled',
							//ctaIcon: 'icon-direct-sale-primary',
							text: state.getString('funnel_counter_user_manual_text_5'),
							routerTo: "/ops/counter",
							ctaText: state.getString('go_to_counter_sale'),
							videoStart: 270,
						},
					]
				},
				{
					video: {
						id: 'l-jOxv_wVkI',
						title: state.getString('funnel_video_fiscalization_title'),
						description: state.getString('funnel_video_fiscalization_description'),
					},
					title: state.getString('fiscalization_of_orders'),
					trackEventId: 'step6_allinone',
					completed: state.completedFiscalization,
					slotName: 'counter-fiscalization',
					actionId: 'emit_receipt',
				},
			]
		},
		funnelsMap: state => {
			return {
				restaurant: {
					bannerData: {
						src: 'https://qromomilan.s3.eu-south-1.amazonaws.com/img/funnel/gestionale.png',
						title: state.getString('funnel_counter_banner_title'),
						text: state.getString('funnel_counter_banner_text'),
					},
					steps: state.counterFunnelSteps
				},
				events: {
					bannerData: {
						src: 'https://qromomilan.s3.eu-south-1.amazonaws.com/img/funnel/gestionale.png',
						//title: state.getString('funnel_banner_title', [state.getString('business_package_skip_cashier')]),
						title: state.getString('funnel_counter_banner_title'),
						text: state.getString('funnel_counter_banner_text'),
					},
					steps: state.counterFunnelSteps
				},
				other: {
					bannerData: {
						src: 'https://qromomilan.s3.eu-south-1.amazonaws.com/img/funnel/gestionale.png',
						//title: state.getString('funnel_banner_title', [state.getString('business_package_counter')]),
						title: state.getString('funnel_counter_banner_title'),
						text: state.getString('funnel_counter_banner_text'),
					},
					steps: state.counterFunnelSteps
				},
			}
		},
		menuItems: state => state.menuStore?.items,
		ready: state => !state.loadingFunnelData && !!state.currBusiness,
		hasActiveFunnel: state => {
			if(!state.ready || !state.userStore?.currPermissions) {
				return false;
			}
			return state.userStore.currPermissions.includes('admin')
				&& !state.currBusiness.dismissed_funnel
				&& state.stepperProgress !== 0
				&& state?.currFunnel?.steps
				&& state?.currFunnel?.steps?.length
		},
		showStepsBottomSheet: state => {
			if(!state.ready || !state.userStore?.currPermissions) {
				return false;
			}
			return state.hasActiveFunnel
				&& state?.currFunnel?.steps?.length >= state.stepperProgress;
		},
		showEndedFunnelPopup: state => {
			if(!state.ready || !state.userStore?.currPermissions) {
				return false;
			}
			return state.hasActiveFunnel
				&& state?.currFunnel?.steps?.length + 1 === state.stepperProgress
				&& !!state?.businessActions && !state?.businessActions?.includes('ended_counter_funnel');
		},
		endedCurrentFunnel: state => {
			if(!state.ready || !state.userStore?.currPermissions) {
				return false;
			}
			return state.hasActiveFunnel
				&& state?.currFunnel?.steps?.length + 1 === state.stepperProgress
		},
		currFunnel: state => {
			if(!state.ready || state.currBusiness?.dismissed_funnel || !state.currBusiness?.type || state.currBusiness?.country !== 'IT') {
				return null;
			}
			return state.funnelsMap[state.currBusiness.type] || null;
		},
		completedProductsRegistry: state => {
			if(!state.ready || !state.menuStore?.items || !Object.keys(state.menuStore.items)?.length) {
				return false;
			}
			return Object.keys(state.menuStore.items).length >= 3;
		},
		completedRoomCreation: state => {
			if(!state.ready || !state.roomsStore?.rooms  || !state.roomsStore.rooms?.length) {
				return false;
			}
			return state.roomsStore.rooms.length > 0;
		},
		completedGoLiveCounter: state => {
			if(!state.ready) {
				return false;
			}
			return !!state.currBusiness.plan_id && state.currBusiness.plan_plugin_counter;
		},
		completedGoLiveQrOrders: state => {
			if(!state.ready) {
				return false;
			}
			return state.currBusiness.plan_id && state.currBusiness.plan_plugin_order_pay;
		},
		completedGoLiveDigitalMenu: state => {
			if(!state.ready) {
				return false;
			}
			return !!state.currBusiness.plan_id;
		},
		completedGoLivePayTable: state => {
			if(!state.ready) {
				return false;
			}
			return !!state.currBusiness.plan_id && !!state.currBusiness.plan_plugin_pay_bill;
		},
		completedStripeLink: state => {
			if(!state.ready) {
				return false;
			}
			return !!state.currBusiness.stripe_account && !!state.currBusiness.config_payments;
		},
		completedFiscalization: state => {
			if(!state.ready) {
				return false;
			}
			return state.businessActions?.includes('emit_receipt');
		},
		completedBusinessInfos: state => {
			if(!state.ready) {
				return false;
			}
			return !!(state.currBusiness.legal_address_id && state.currBusiness.sdi && state.currBusiness.vat && state.currBusiness.fiscal_code && state.currBusiness.fiscal_name);
		},
		completedShopHardware: state => {
			if(!state.ready) {
				return false;
			}
			return !!state.wentToShop && state.businessActions?.includes('buy_hardware');
		},
		stepperProgress: state => {
			if(!state.ready || !state?.currFunnel?.steps) {
				return 0;
			}
			let progress = 1;
			let steps = state.currFunnel.steps;
			for (let i = 0; i < steps.length; i++) {
				if(steps[i].completed) {
					progress++;
				} else {
					break;
				}
			}
			return progress;
		},
		currStep: state => {
			if(!state.ready || !state?.currFunnel?.steps?.length || !state.stepperProgress || !state?.currFunnel?.steps?.[state.stepperProgress - 1]) {
				return null;
			}
			return state.currFunnel.steps[state.stepperProgress - 1];
		},
		showDismissFunnelButton: state => {
			if(!state.ready || !state.currFunnel) {
				return false;
			}
			let currGoLiveStep = state.currFunnel.steps.find(step => step?.slotName?.includes && step.slotName.includes('go-live'));
			return currGoLiveStep && currGoLiveStep.completed;
		}
	},
	actions: {
		init(callback = null) {
			if(this.loadingFunnelData || !this.currBusiness || this.currBusiness?.dismissed_funnel) {
				return;
			}
			let finalFn = () => {
				if(this.menuStore.loadedBusinessMenu && this.roomsStore.roomsTables) {
					this.loadingFunnelData = false;
					if(!this.businessActions && this.currStep && (this.currStep?.shouldLoadActions || this.currStep?.actionId || this.currStep?.listItems?.some(item => !!item.businessActionId))) {
						this.getBusinessActions(()=>{
							if(callback) { callback(); }
						})
					} else if(callback) {
						callback();
					}
				}
			}
			if(this.menuStore.loadedBusinessMenu && this.roomsStore.rooms) {
				if(callback) { callback(); }
			} else {
				this.loadingFunnelData = true;
				this.menuStore.loadMenu(() => {
					finalFn();
				});
				this.roomsStore.getBusinessRooms(() => {
					finalFn();
				});
			}
		},
		startPollingForStripeLink() {
			if(this.fetchingBusiness) {
				return;
			}
			let check = ()=>{
				clearTimeout(this.pollingTimeout);
				if(!this.completedStripeLink || this.pollingIterations <= 20) {
					this.pollingIterations++;
					this.fetchingBusiness = true;
					this.userStore.refreshBusiness(this.userStore.currBusinessId, ()=>{
						this.fetchingBusiness = false;
						if(this.completedStripeLink) {
							this.pollingIterations = 0;
						} else {
							this.pollingTimeout = setTimeout(check, 10000);
						}
					});
				}
			}
			check();
		},
		addBusinessAction(actionId, callback = null) {
			if(!this.userStore?.currBusinessId || this.loadingFunnelData || this.loadingActions) {
				if(callback) { callback(false); }
				return;
			}
			this.loadingActions = true;
			API.init().addBusinessAction(this.userStore.currBusinessId, actionId, response => {
				this.loadingActions = false;
				if (response && response.result === "OK") {
					this.businessActions.push(actionId);
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false); }
				}
			});
		},
		getBusinessActions(callback = null, force = false) {
			if(!this.userStore?.currBusinessId || this.loadingFunnelData || this.loadingActions) {
				if(callback) { callback(false); }
				return;
			}
			if(!force && this.businessActions) {
				if(callback) { callback(this.businessActions); }
				return;
			} else {
				this.loadingActions = true;
				this.businessActions = [];
				API.init().getBusinessActions(this.userStore.currBusinessId, response => {
					this.loadingActions = false;
					if (response && response.result === "OK" && response?.actions?.map) {
						this.businessActions = response.actions.map(action => action.action);
						if(callback) { callback(true) }
					} else {
						if(callback) { callback(false); }
					}
				});
			}
		},
		toggleCurrentFunnel(show, callback = null) {
			if(!this.currBusiness) { return; }
			this.userStore.updateBusinessFunnelPreferences(!show, success=> {
				if (callback) { callback(success); }
			});
		},
		sendZarazStepEvent(stepName, params = {}) {
			if(typeof zaraz !== 'undefined') {
				let finalParams = params ? params : {};
				finalParams.business_id = this.userStore.currBusinessId;
				zaraz.track(stepName, params);
			}
		}
	}
});